<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('employeesIncentives.EmployeeIncentives')"
          :description="$t('employeesIncentives.FromHereYouCanSetUpANewEmployeeIncentiveProcess')"
        />
        <indexTable
          :searchText="$t('employeesIncentives.SearchForAnOperation')"
          :emptyTableText="$t('employeesIncentives.ThereAreNoIncentives')"
          :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
          :buttonRole="$user.role.employees_incentives_add"
          :statusSearch="true"
          :statusValues="[
            { title: $t('orders.situation'), value: ''},
            { title: $t('orders.all'), value: ''},
            { title: $t('invoices.draft'), value: 0 },
            { title: $t('invoices.approved'), value: 1 },
          ]"
          :cloumns="[
            { column: 'code', title: $t('employeesIncentives.incentive'), type: 'mainLink', sort: true },
            { column: 'employee_id',  title:$t('employeesIncentives.functionary'),  type: 'link', to:'employee', sort: true, link: true},
            { column: 'date', title: $t('employeesIncentives.date'), type: 'text', sort: true },
            {
              column: 'description',
              title: $t('employeesIncentives.thatsAbout'),
              type: 'text',
              sort: true,
            },
            { column: 'notes', title: $t('employeesIncentives.note'), type: 'text', sort: true },
            {
              column: 'cost',
              title: $t('employeesIncentives.cost'),
              type: 'text',
              sort: true,
              after: $option.currency,
            },
            {column: 'status', title: $t('bills.situation') ,type: 'status' , sort: true ,  values: [
                {title: $t('invoices.draft'), value: 0, color: 'dark'},
                {title: $t('invoices.approved'), value: 1, color: 'success'},
              ]
            },
            {
              column: 'options',
              title: $t('employeesIncentives.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {name: 'printEmployeesIncentive',   role: true},
                {
                  name: 'edit',
                  role: $user.admin || $user.role.employees_incentives_edit,
                },
                {name: 'file'},
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role: $user.admin || $user.role.employees_incentives_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from "../elements/index/indexHeader.vue";
import indexTable from "../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/employeesIncentives"
    };
  },
  components: {
    indexHeader,
    indexTable,
  },
};
</script>
